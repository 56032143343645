import { ref, Ref } from "vue";
import { QuoteAdminStatuses } from "@/enums/QuoteAdminStatuses";
import QuoteService from "@/services/QuoteService";
import QuoteInterface from "@/types/api/QuoteInterface";
import RfqOptionsInterface from "@/types/quote-details/RfqOptionsInterface";

export default () => {
  const initSummaryLoading = ref(false);
  const summaryLoading = ref(false);

  const fetchQuoteV2Summary = (
    quote: Ref<QuoteInterface>,
    quoteOptions?: Ref<RfqOptionsInterface>,
    withLoading = true
  ) => {
    if (withLoading) {
      initSummaryLoading.value = true;
    }
    summaryLoading.value = true;
    // We use a patch request before status Ordered because the request returns a summary and makes changes to the database.
    return (
      quote.value.status >= QuoteAdminStatuses.Ordered
        ? QuoteService.getQuoteV2Summary
        : QuoteService.updateQuoteV2Summary
    )(quote.value.id)
      .then((summary) => {
        if (!summary.data.data) {
          return;
        }
        const {
          partsProduction,
          qualityControl,
          shipping,
          totalPrice,
          prototype,
          massProduction,
          discount,
        } = summary.data.data;
        if (quoteOptions?.value) {
          quoteOptions.value.totalPartProduction.price = partsProduction;
          quoteOptions.value.totalQualityControl.price = qualityControl;
          quoteOptions.value.totalShipping.price = shipping;
          quoteOptions.value.totalPrice = totalPrice;
          quoteOptions.value.discount.value = discount;
        } else {
          quote.value.options.totalPartProduction.price = partsProduction;
          quote.value.options.totalQualityControl.price = qualityControl;
          quote.value.options.totalShipping.price = shipping;
          quote.value.options.totalPrice = totalPrice;
          quote.value.options.discount.value = discount;
        }
        quote.value.prototypes = prototype;
        quote.value.massProduction = massProduction;
      })
      .finally(() => {
        if (withLoading) {
          initSummaryLoading.value = false;
        }
        summaryLoading.value = false;
      });
  };
  return {
    summaryLoading,
    initSummaryLoading,
    fetchQuoteV2Summary,
  };
};
