import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5b36191a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "user" }
const _hoisted_3 = { class: "user--name" }
const _hoisted_4 = { class: "quotes-table--title rfq-number" }
const _hoisted_5 = { class: "quote-number" }
const _hoisted_6 = {
  key: 0,
  class: "quote-revision"
}
const _hoisted_7 = { class: "quotes-table--part" }
const _hoisted_8 = { class: "quotes-table--status" }
const _hoisted_9 = { class: "quotes-table--status-wrapper" }
const _hoisted_10 = {
  key: 1,
  class: "quotes-table--sticker-message"
}
const _hoisted_11 = { class: "quotes-table--time quotes-table--center" }
const _hoisted_12 = { class: "quotes-table--time-title" }
const _hoisted_13 = { class: "user" }
const _hoisted_14 = {
  key: 0,
  class: "user--wrapper"
}
const _hoisted_15 = { class: "user--name" }
const _hoisted_16 = { key: 1 }
const _hoisted_17 = { class: "quotes-table--complexity quotes-table--center" }
const _hoisted_18 = { class: "quotes-table--amount quotes-table--center" }
const _hoisted_19 = { class: "quotes-table--view-button-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["default-table--row clickable-table-row", {disabled: $setup.isViewingDraftsDisabled || !$props.rfqItem.userId.customer?.id}]),
    onClick: $setup.onMoveToQuote
  }, [
    _createElementVNode("div", null, [
      (!$props.rfqItem.userId.customer?.id)
        ? (_openBlock(), _createElementBlock("p", _hoisted_1))
        : (_openBlock(), _createBlock(_component_router_link, {
            key: 1,
            class: "quotes-table--title company-link",
            to: {name: $setup.RouteName.CustomerDetails, params:{ customerId: $props.rfqItem.userId.customer.id}},
            onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($props.rfqItem.userId.customer.fullName), 1)
            ]),
            _: 1
          }, 8, ["to"])),
      _createElementVNode("div", _hoisted_2, [
        _createVNode($setup["UserAvatar"], {
          class: "user--avatar",
          id: $props.rfqItem.userId.avatar,
          size: 16,
          name: $props.rfqItem.userId.firstName,
          alt: "customer avatar"
        }, null, 8, ["id", "name"]),
        _createElementVNode("span", _hoisted_3, _toDisplayString($props.rfqItem.userId.firstName) + " " + _toDisplayString($props.rfqItem.userId.lastName), 1)
      ])
    ]),
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, "#" + _toDisplayString($props.rfqItem.number), 1),
        ($props.rfqItem.revision > 1)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, "Rev " + _toDisplayString($props.rfqItem.revision), 1))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("p", _hoisted_7, _toDisplayString($setup.partText) + " " + _toDisplayString($props.rfqItem.name ? `| ${$props.rfqItem.name}` : ""), 1)
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("div", _hoisted_9, [
        _createTextVNode(_toDisplayString($setup.getStatusName($props.rfqItem.status, $props.rfqItem.isDeleted)), 1),
        ($props.rfqItem.labelStatus === $setup.StickerStatusesEnum.ACTIVE && $props.rfqItem.label)
          ? (_openBlock(), _createBlock($setup["NTag"], {
              key: 0,
              class: "info",
              bordered: false
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString($props.rfqItem.label.toLowerCase()), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      ($props.rfqItem.purchaseOrderFileId)
        ? (_openBlock(), _createBlock($setup["DownloadPO"], {
            key: 0,
            class: "quotes-table--part link",
            quote: $props.rfqItem
          }, null, 8, ["quote"]))
        : _createCommentVNode("", true),
      ($props.rfqItem.messageStatus === $setup.StickerStatusesEnum.ACTIVE && $props.rfqItem.message)
        ? (_openBlock(), _createElementBlock("p", _hoisted_10, _toDisplayString($props.rfqItem.message), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createElementVNode("p", _hoisted_12, _toDisplayString($setup.isOrdered ? "Time Since ordered" : $setup.isQuoted ?  "Time Since quoted" : "Time To quote"), 1),
      _createElementVNode("p", {
        class: _normalizeClass(["quotes-table--quote-time", {error: $setup.withDelay}])
      }, _toDisplayString($setup.isOrdered ? $setup.getFormattedTimeDiff($setup.orderedTimeRfqTimeComposable) : $setup.isQuoted ? $setup.getFormattedTimeDiff($setup.quotedTimeRfqTimeComposable) : $setup.timeMessage), 3)
    ]),
    _createElementVNode("div", _hoisted_13, [
      ($setup.accountManager || $setup.techSolutionEngineer)
        ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList([$setup.accountManager, $setup.techSolutionEngineer].filter((item) => !!item), (item) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "user--item",
                key: item.id
              }, [
                _createVNode($setup["UserAvatar"], {
                  class: "user--avatar",
                  id: item.userId.avatar,
                  size: 16,
                  name: item.userId.firstName,
                  alt: "user avatar"
                }, null, 8, ["id", "name"]),
                _createElementVNode("span", _hoisted_15, _toDisplayString(item.userId.firstName) + " " + _toDisplayString(item.userId.lastName), 1)
              ]))
            }), 128))
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_16, "-"))
    ]),
    _createElementVNode("div", _hoisted_17, [
      _createVNode($setup["NTag"], {
        class: _normalizeClass($setup.getTagStyle($setup.complexityLevel)),
        bordered: false
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString($setup.getComplexityLevel($setup.complexityLevel)), 1)
        ]),
        _: 1
      }, 8, ["class"])
    ]),
    _createElementVNode("div", _hoisted_18, _toDisplayString($setup.formatPrice($props.rfqItem.totalPrice, $setup.currencySymbol)), 1),
    _createElementVNode("div", _hoisted_19, [
      _createVNode($setup["NButton"], {
        class: "quotes-table--view-button",
        onClick: $setup.onMoveToQuote,
        disabled: $setup.isViewingDraftsDisabled,
        type: "info",
        ghost: ""
      }, {
        default: _withCtx(() => [
          _createTextVNode("View")
        ]),
        _: 1
      }, 8, ["disabled"])
    ])
  ], 2))
}