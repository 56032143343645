import { ref } from "vue";
import router from "@/router";
import QuoteService from "@/services/QuoteService";
import RfqTeamInterface from "@/types/quote-details/RfqTeamInterface";

export default () => {
  const initTeamLoading = ref(false);
  const quoteTeam = ref<RfqTeamInterface>({} as RfqTeamInterface);
  const fetchQuoteV2Team = (withLoader = true) => {
    if (withLoader) {
      initTeamLoading.value = true;
    }

    return QuoteService.getQuoteV2Team(`${router.currentRoute.value.params.id}`)
      .then((res) => {
        quoteTeam.value = res.data.data;
      })
      .finally(() => {
        if (withLoader) {
          initTeamLoading.value = false;
        }
      });
  };
  return {
    initTeamLoading,
    quoteTeam,
    fetchQuoteV2Team,
  };
};
