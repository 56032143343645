import { reactive } from "vue";
import { Module } from "vuex";
import { RootState } from "@/types/store/RootState";
import UserInterface from "@/types/api/UserInterface";

const internalUsersState = reactive({
  internalUsers: [] as UserInterface[],
});
export type InternalUsersState = typeof internalUsersState;

const store: Module<InternalUsersState, RootState> = {
  namespaced: true,
  state: internalUsersState,
  getters: {
    getInternalUsers(state: InternalUsersState) {
      return state.internalUsers;
    },
    getInternalUserById: (state: InternalUsersState) => (id: string) => {
      return state.internalUsers.find((item) => item.id === id);
    },
  },
  mutations: {
    setInternalUsers(
      state: InternalUsersState,
      payload: UserInterface[]
    ): void {
      state.internalUsers = payload;
    },
  },
  actions: {
    setInternalUsers({ commit }, payload: UserInterface[]): void {
      commit("setInternalUsers", payload);
    },
  },
};

export default store;
