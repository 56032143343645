import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, withModifiers as _withModifiers, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-279166a3"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "notifications--container",
  ref: "notificationsScrollbar"
}
const _hoisted_2 = {
  key: 1,
  class: "notifications--list"
}
const _hoisted_3 = ["data-index"]
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "notification-item--container" }
const _hoisted_6 = { class: "notification-item--headline-container" }
const _hoisted_7 = { class: "notification-item--headline" }
const _hoisted_8 = {
  key: 0,
  class: "notification-item--text-wrapper"
}
const _hoisted_9 = { class: "notification-item--text" }
const _hoisted_10 = ["src", "onClick"]
const _hoisted_11 = { class: "notification-item--info" }
const _hoisted_12 = { class: "tooltip--text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode($setup["NScrollbar"], {
      class: "notifications--scroll",
      onScroll: $setup.onScrollNotifications
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          (!$props.notifications.length)
            ? (_openBlock(), _createBlock($setup["EmptyState"], {
                key: 0,
                class: "empty-state"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("p", null, "No " + _toDisplayString($props.activeTab) + " updates", 1)
                ]),
                _: 1
              }))
            : (_openBlock(), _createElementBlock("ul", _hoisted_2, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.notifications, (item, itemIndex) => {
                  return (_openBlock(), _createElementBlock("li", {
                    class: _normalizeClass(["notification-item lazy-item", {new: !item.isRead}]),
                    key: item.id,
                    "data-index": itemIndex
                  }, [
                    _createElementVNode("div", {
                      class: "notification-item-container",
                      onClick: ($event: any) => ($setup.moveToTargetPage(item))
                    }, [
                      ($setup.visibleItems.includes(itemIndex))
                        ? (_openBlock(), _createBlock($setup["UserAvatar"], {
                            key: 0,
                            class: "notification-item--avatar",
                            id: item.sentByUserId?.avatar,
                            size: $props.avatarSize,
                            name: item.sentByUserId.firstName
                          }, null, 8, ["id", "size", "name"]))
                        : (_openBlock(), _createBlock($setup["UserAvatar"], {
                            key: 1,
                            class: "notification-item--avatar",
                            size: $props.avatarSize,
                            name: item.sentByUserId.firstName
                          }, null, 8, ["size", "name"])),
                      _createElementVNode("div", _hoisted_5, [
                        _createElementVNode("div", _hoisted_6, [
                          _createElementVNode("p", _hoisted_7, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.getTitle(item), (part, partIndex) => {
                              return (_openBlock(), _createElementBlock("span", {
                                key: partIndex,
                                class: _normalizeClass($setup.getTextClass(part))
                              }, _toDisplayString(part.value), 3))
                            }), 128))
                          ]),
                          _createElementVNode("div", {
                            class: _normalizeClass(["notification-item--actions", {customer: $setup.isCustomerUserRole}])
                          }, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.tooltipIcons, (icon) => {
                              return (_openBlock(), _createElementBlock("div", {
                                class: "tooltip",
                                key: icon.key
                              }, [
                                _createVNode($setup["TooltipWrapper"], {
                                  placement: "top-end",
                                  text: icon.key === $setup.UpdateIconEnum.MARK_AS_UNREAD ? `${icon.tooltipText}${item.isRead ? 'un': ''}read` : icon.tooltipText
                                }, {
                                  trigger: _withCtx(() => [
                                    _createElementVNode("div", null, [
                                      (icon.key === $setup.UpdateIconEnum.MARK_AS_UNREAD)
                                        ? (_openBlock(), _createBlock($setup["NCheckbox"], {
                                            key: 0,
                                            checked: item.isUnread,
                                            "onUpdate:checked": ($event: any) => ((item.isUnread) = $event),
                                            onClick: _withModifiers(($event: any) => ($setup.onClickCheckbox(item)), ["stop"])
                                          }, null, 8, ["checked", "onUpdate:checked", "onClick"]))
                                        : (_openBlock(), _createBlock($setup["NButton"], {
                                            key: 1,
                                            class: _normalizeClass(["update-icon", icon.key]),
                                            circle: "",
                                            ghost: "",
                                            onClick: _withModifiers(($event: any) => ($setup.onClickIcon(icon.key, itemIndex)), ["stop"])
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode($setup["NIcon"], {
                                                size: icon.size,
                                                component: icon.icon
                                              }, null, 8, ["size", "component"])
                                            ]),
                                            _: 2
                                          }, 1032, ["class", "onClick"]))
                                    ])
                                  ]),
                                  _: 2
                                }, 1032, ["text"])
                              ]))
                            }), 128))
                          ], 2)
                        ]),
                        (item.text)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                              _createElementVNode("p", _hoisted_9, _toDisplayString(item.text), 1),
                              (item.attachments?.originalUploadId?.link)
                                ? (_openBlock(), _createElementBlock("img", {
                                    key: 0,
                                    class: "notification-item--image",
                                    src: item.attachments?.originalUploadId?.link,
                                    alt: "Attachment",
                                    onClick: _withModifiers(($event: any) => ($setup.imageClick(item.attachments?.originalUploadId?.link)), ["stop"])
                                  }, null, 8, _hoisted_10))
                                : _createCommentVNode("", true)
                            ]))
                          : _createCommentVNode("", true),
                        _createElementVNode("p", _hoisted_11, [
                          _createVNode($setup["NTooltip"], {
                            class: "tooltip--wrapper",
                            trigger: "hover",
                            placement: "top-start",
                            "show-arrow": false
                          }, {
                            trigger: _withCtx(() => [
                              _createElementVNode("span", null, _toDisplayString($setup.dayjs().to($setup.dayjs(item.createdAt))), 1)
                            ]),
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_12, _toDisplayString($setup.dayjs(item.createdAt).format("MMM D, YYYY. HH:mm")), 1)
                            ]),
                            _: 2
                          }, 1024)
                        ])
                      ])
                    ], 8, _hoisted_4)
                  ], 10, _hoisted_3))
                }), 128))
              ]))
        ], 512)
      ]),
      _: 1
    }),
    ($setup.showImageModal)
      ? (_openBlock(), _createBlock($setup["ImageGalleryModal"], {
          key: 0,
          show: $setup.showImageModal,
          "onUpdate:show": _cache[0] || (_cache[0] = ($event: any) => (($setup.showImageModal) = $event)),
          link: $setup.activeImageLink,
          onClose: $setup.closeImageGalleryModal,
          onMaskClick: $setup.closeImageGalleryModal
        }, null, 8, ["show", "link"]))
      : _createCommentVNode("", true)
  ], 64))
}