import { ref } from "vue";
import QuoteService from "@/services/QuoteService";
import router from "@/router";
import RfqOptionsInterface from "@/types/quote-details/RfqOptionsInterface";

export default () => {
  const initOptionsLoading = ref(false);
  const optionsLoading = ref(false);
  const quoteOptions = ref<RfqOptionsInterface>({} as RfqOptionsInterface);
  const fetchQuoteV2Options = (withLoading = true) => {
    if (withLoading) {
      initOptionsLoading.value = true;
    }
    optionsLoading.value = true;
    return QuoteService.getQuoteV2Options(
      `${router.currentRoute.value.params.id}`
    )
      .then((res) => {
        quoteOptions.value = res.data.data;
      })
      .finally(() => {
        if (withLoading) {
          initOptionsLoading.value = false;
        }
        optionsLoading.value = false;
      });
  };
  return {
    optionsLoading,
    initOptionsLoading,
    quoteOptions,
    fetchQuoteV2Options,
  };
};
