import { StaticTeamRoleEnum } from "@/enums/StaticTeamRole";
import { UserTypes } from "@/enums/UserTypes";
import { MemberInterface } from "@/types/MemberInterface";
import { RFQTeamMemberInterface } from "@/types/quote-details/RfqTeamInterface";

export const sortTeamMembers = (
  members: MemberInterface[],
  length?: number
): MemberInterface[] => {
  const customers = getSortedMembers(members, UserTypes.customer);
  const internals = getSortedMembers(members, UserTypes.internal);
  const membersArray = [...customers, ...internals];
  return length ? membersArray.slice(0, length) : membersArray;
};

export const sortRfqTeamMembers = (
  members: RFQTeamMemberInterface[],
  length?: number
): RFQTeamMemberInterface[] => {
  const customers = getSortedRfqMembers(members, UserTypes.customer);
  const internals = getSortedRfqMembers(members, UserTypes.internal);
  const membersArray = [...customers, ...internals];
  return length ? membersArray.slice(0, length) : membersArray;
};

export const getSortedMembers = (
  members: MemberInterface[],
  type: UserTypes
): MemberInterface[] => {
  return members
    .filter((item) => item?.userId?.type === type)
    ?.sort((a, b) => {
      if (a.staticRole && b.staticRole) {
        return a.staticRole === StaticTeamRoleEnum.ACCOUNT_MANAGER ? -1 : 1;
      } else {
        return a.staticRole
          ? -1
          : a.userId.firstName.localeCompare(b.userId.firstName);
      }
    });
};

export const getSortedRfqMembers = (
  members: RFQTeamMemberInterface[],
  type: UserTypes
): RFQTeamMemberInterface[] => {
  return members
    .filter((item) => item?.type === type)
    ?.sort((a, b) => {
      if (a.staticRole && b.staticRole) {
        return a.staticRole === StaticTeamRoleEnum.ACCOUNT_MANAGER ? -1 : 1;
      } else {
        return a.staticRole ? -1 : a.firstName.localeCompare(b.firstName);
      }
    });
};
