import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5aeafa94"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "quotes-sticky-header side-padding" }
const _hoisted_2 = { class: "quotes--filters" }
const _hoisted_3 = { class: "quotes-filter--wrapper" }
const _hoisted_4 = { class: "filter--actions" }
const _hoisted_5 = {
  key: 2,
  class: "quotes-table--header quotes-table--row default-table--header default-table--header-sortable"
}
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "quotes--page-counters side-padding" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode($setup["Loader"], { loading: $setup.isLoading }, null, 8, ["loading"]),
    _createElementVNode("div", _hoisted_1, [
      ($setup.summary.quotes)
        ? (_openBlock(), _createBlock($setup["AdminTabs"], {
            key: 0,
            class: "quotes--tabs",
            summary: $setup.summary
          }, null, 8, ["summary"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode($setup["NDropdown"], {
            class: "main-dropdown filter-menu quotes-filter quotes-filter-admin",
            show: $setup.showFilters,
            "onUpdate:show": [
              _cache[0] || (_cache[0] = ($event: any) => (($setup.showFilters) = $event)),
              $setup.onShowFiltersTriggered
            ],
            options: $setup.filterOptions,
            placement: "bottom-start",
            trigger: "click",
            onSelect: $setup.handleMainFiltersSelect
          }, {
            default: _withCtx(() => [
              _createVNode($setup["NButton"], {
                class: "n-button-filter",
                round: ""
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Add Filter")
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["show", "options"]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.filterListGrouped, (item) => {
            return (_openBlock(), _createElementBlock(_Fragment, null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.array, (arrayItem) => {
                return (_openBlock(), _createBlock($setup["FilterTag"], {
                  key: arrayItem.key || arrayItem.value,
                  label: item.label,
                  value: arrayItem.value,
                  loading: $setup.isLoading,
                  onDeleteItem: ($event: any) => ($setup.removeFilterTag(item.parent, arrayItem.key || arrayItem.value))
                }, null, 8, ["label", "value", "loading", "onDeleteItem"]))
              }), 128))
            ], 64))
          }), 256)),
          _createElementVNode("div", _hoisted_4, [
            ($setup.showClearAllFilters)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass(["clear-filters", {disabled: $setup.isLoading}]),
                  onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.onClearFilterTags())),
                  role: "button"
                }, "Clear all", 2))
              : _createCommentVNode("", true),
            ($setup.filterList.length >= 2)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: "save-filters",
                  role: "button",
                  onClick: $setup.openSaveFiltersModal
                }, "Save filters"))
              : _createCommentVNode("", true)
          ])
        ]),
        _createVNode($setup["SearchInput"], {
          class: "search-input",
          modelValue: $setup.searchSet,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.searchSet) = $event)),
          onChange: $setup.onSearch
        }, null, 8, ["modelValue"])
      ]),
      (!$setup.isLoading && $setup.quoteList.length === 0)
        ? (_openBlock(), _createBlock($setup["NEmpty"], {
            key: 1,
            class: "quotes--empty-block",
            description: "No Data"
          }))
        : _createCommentVNode("", true),
      ($setup.quoteListWithStickers.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.tableHeader, (item) => {
              return (_openBlock(), _createElementBlock("span", {
                key: item.title,
                class: _normalizeClass({clickable: item.sortableId}),
                onClick: ($event: any) => ($setup.tableSort(item.sortableId))
              }, [
                _createTextVNode(_toDisplayString(item.title), 1),
                (item.sortableId === $setup.activeSortableId)
                  ? (_openBlock(), _createElementBlock("i", {
                      key: 0,
                      class: _normalizeClass(["sort-triangle", $setup.getTriangleClass($setup.orderBy)])
                    }, null, 2))
                  : _createCommentVNode("", true)
              ], 10, _hoisted_6))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ]),
    ($setup.quoteListWithStickers)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["quote-list-wrapper side-padding", {'padding-bottom': $setup.totalPages === 1}])
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.quoteListWithStickers, (item) => {
            return (_openBlock(), _createBlock($setup["QuoteItem"], {
              class: _normalizeClass(["quotes-table--item quotes-table--row", {'last-visited-list-item': $setup.lastVisitedQuoteId === item.id}]),
              key: item.number+item.createdAt,
              rfqItem: item,
              onMoveToQuote: $setup.onMoveToQuote
            }, null, 8, ["rfqItem", "class"]))
          }), 128))
        ], 2))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_7, [
      _withDirectives(_createElementVNode("div", { class: "quotes--counter" }, _toDisplayString($setup.paginationCurrentItemsMessage), 513), [
        [_vShow, !$setup.loading]
      ]),
      _withDirectives(_createVNode($setup["NPagination"], {
        class: "quotes--pagination",
        page: $setup.currentPage,
        "onUpdate:page": [
          _cache[3] || (_cache[3] = ($event: any) => (($setup.currentPage) = $event)),
          _cache[4] || (_cache[4] = ($event: any) => ($setup.onFiltersUpdated(false)))
        ],
        "page-count": $setup.totalPages,
        "page-slot": 8
      }, null, 8, ["page", "page-count"]), [
        [_vShow, $setup.totalPages > 1]
      ])
    ]),
    _createVNode($setup["FilterModal"], {
      showModal: $setup.showSaveFiltersModal,
      groupedFilter: $setup.filterListGroupedPopup,
      onClose: $setup.closeSaveFiltersModal,
      onFilterGroupCreated: $setup.filterGroupCreated
    }, null, 8, ["showModal", "groupedFilter"])
  ], 64))
}