import { reactive } from "vue";
import { Module } from "vuex";
import { RootState } from "@/types/store/RootState";
import { FileEntityInterface } from "@/types/FileEntity";

const filesState = reactive({
  files: [] as FileEntityInterface[],
});
export type FilesState = typeof filesState;

const store: Module<FilesState, RootState> = {
  namespaced: true,
  state: filesState,
  getters: {
    getFiles(state: FilesState) {
      return state.files;
    },
    getFileById: (state: FilesState) => (id: string) => {
      return state.files.find((item) => item.id === id);
    },
  },
  mutations: {
    setFile(state: FilesState, payload: FileEntityInterface): void {
      const index = state.files.findIndex((item) => item.id === payload.id);
      if (index !== -1) {
        state.files[index] = payload;
      } else {
        state.files.push(payload);
      }
    },
    resetFiles(state: FilesState): void {
      Object.assign(state, {
        files: [] as FileEntityInterface[],
      });
    },
  },
  actions: {
    setFile({ commit }, payload: FileEntityInterface): void {
      commit("setFile", payload);
    },
    resetFiles({ commit }): void {
      commit("resetFiles");
    },
  },
};

export default store;
