import { AxiosResponse } from "axios";

import ApiService from "@/services";
import { NotificationFolderEnum } from "@/enums/NotificationFolderEnum";

export default class NotificationService {
  static getNotifications(params: {
    limit: number;
    skip: number;
    folder?: NotificationFolderEnum;
    id?: string;
  }): Promise<AxiosResponse> {
    return ApiService.get("/v1/notifications", { params });
  }

  static markAsRead(ids: string[]): Promise<AxiosResponse> {
    return ApiService.post("/v1/notifications/mark-as-read", { ids });
  }

  static changeNotificationFolder(
    id: string,
    folder: NotificationFolderEnum
  ): Promise<AxiosResponse> {
    return ApiService.patch(`/v1/notifications/${id}`, {
      folder,
    });
  }
  static changeNotificationReadStatus(
    id: string,
    isRead: boolean
  ): Promise<AxiosResponse> {
    return ApiService.patch(`/v1/notifications/${id}`, {
      isRead,
    });
  }
}
