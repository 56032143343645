import { reactive } from "vue";
import { Module } from "vuex";
import { NotificationFolderEnum } from "@/enums/NotificationFolderEnum";
import NotificationService from "@/services/NotificationService";
import FileService from "@/services/FileService";
import { RootState } from "@/types/store/RootState";
import {
  NotificationAttachmentInterface,
  NotificationCountsInterface,
  NotificationInterface,
  NotificationItemInterface,
} from "@/types/NotificationInterface";
import { FileEntityInterface } from "@/types/FileEntity";

const notificationState = reactive({
  notifications: {} as NotificationInterface,
  loading: false,
  showNotifications: false,
});
export type NotificationsState = typeof notificationState;

const store: Module<NotificationsState, RootState> = {
  namespaced: true,
  state: notificationState,
  getters: {
    getNotifications(state: NotificationsState) {
      state.notifications.items = state.notifications?.items
        ?.map((item) => ({
          ...item,
          isUnread: !item.isRead,
        }))
        ?.sort(
          (a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
      return state.notifications;
    },
    getNotificationsByFolder:
      (state: NotificationsState) => (folder: NotificationFolderEnum) => {
        return state?.notifications.items
          ?.filter((item) => item.folder === folder)
          ?.sort(
            (a, b) =>
              new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          );
      },
    getNotificationsCountByFolder:
      (state: NotificationsState) => (folder: NotificationFolderEnum) => {
        return state?.notifications.counts[folder];
      },
    getUnreadNotificationCount(state: NotificationsState) {
      return state?.notifications.items?.filter((item) => !item.isRead).length;
    },
    getNotificationWithImageCount(state: NotificationsState) {
      return state?.notifications?.attachments?.length || 0;
    },
    getNotificationAttachments(state: NotificationsState) {
      return state?.notifications?.attachments;
    },
  },
  mutations: {
    unshiftNotification(
      state: NotificationsState,
      notification: NotificationItemInterface
    ): void {
      if (
        !state.notifications.items.some((item) => item.id === notification.id)
      ) {
        state.notifications.items.unshift(notification);
        state.notifications.counts.inbox =
          +state.notifications.counts.inbox + 1;
        if (
          (notification.attachments as NotificationAttachmentInterface[]).length
        ) {
          state.notifications.attachments.push(
            ...(notification.attachments as NotificationAttachmentInterface[])
          );
        }
      }
    },
    updateNotificationItem(
      state: NotificationsState,
      payload: { index: number; notification: NotificationItemInterface }
    ): void {
      state.notifications.items[payload.index] = {
        ...state.notifications.items?.[payload.index],
        ...payload.notification,
      };
    },
    updateNotificationCounts(
      state: NotificationsState,
      payload: NotificationCountsInterface
    ): void {
      state.notifications.counts = payload;
    },
    setNotifications(
      state: NotificationsState,
      payload: NotificationInterface
    ): void {
      if (!state.notifications?.items?.length) {
        state.notifications = payload;
      } else {
        const dataToAdd = payload.items.filter(
          (item) =>
            !state.notifications.items
              .map((stateItem) => stateItem.id)
              .includes(item.id)
        );
        state.notifications.items.push(...dataToAdd);
        state.notifications.counts = payload.counts;
        state.notifications.attachments.push(...payload.attachments);
      }
    },
    cleanNotifications(state: NotificationsState): void {
      state.notifications.items = [];
    },
    deleteNotification(state: NotificationsState, payload: string): void {
      const indexForDelete = state.notifications.items.findIndex(
        (item) => item.id === payload
      );
      const folderForReduce = state.notifications.items[indexForDelete].folder;
      state.notifications.counts[folderForReduce] =
        +state.notifications.counts[folderForReduce] - 1;
      state.notifications.items.splice(indexForDelete, 1);
    },
    setLoader(state: NotificationsState, payload: boolean): void {
      state.loading = payload;
    },
    showNotifications(state: NotificationsState, payload: boolean): void {
      state.showNotifications = payload;
    },
  },
  actions: {
    addNewNotification({ commit }, notification) {
      commit("unshiftNotification", notification);
    },
    updateNotification({ commit, state }, notification) {
      const updatedMessageIndex = state.notifications.items.findIndex(
        (item) => {
          return notification.id === item.id;
        }
      );
      if (updatedMessageIndex !== -1) {
        const previousAttachments = state.notifications.items[
          updatedMessageIndex
        ]?.attachments as FileEntityInterface;
        const updatedNotification = {
          ...notification,
          ...(notification.attachments?.length
            ? {
                attachments: notification.attachments
                  .map((item: NotificationAttachmentInterface) => item.fileId)
                  .includes(previousAttachments?.id)
                  ? previousAttachments
                  : undefined,
              }
            : {}),
        };

        if (
          (notification.attachments as NotificationAttachmentInterface[])
            ?.length
        ) {
          const attachmentsToUpdate = (
            notification.attachments as NotificationAttachmentInterface[]
          ).map((attachmentItem) =>
            !state.notifications.attachments.some(
              (notificationAttachmentItem) =>
                notificationAttachmentItem.fileId === attachmentItem.fileId
            ) ||
            !state.notifications.attachments.some(
              (notificationAttachmentItem) =>
                notificationAttachmentItem.targetId === attachmentItem.targetId
            )
              ? attachmentItem
              : null
          );
          state.notifications.attachments.push(
            ...(attachmentsToUpdate.filter(
              (item) => item !== null
            ) as NotificationAttachmentInterface[])
          );
        }

        commit("updateNotificationItem", {
          index: updatedMessageIndex,
          notification: updatedNotification,
        });
      }
    },
    updateNotificationCounts(
      { commit },
      payload: NotificationCountsInterface
    ): void {
      commit("updateNotificationCounts", payload);
    },
    deleteNotification({ commit }, notificationId): void {
      commit("deleteNotification", notificationId);
    },
    showNotifications({ commit }, payload: boolean): void {
      commit("showNotifications", payload);
    },
    async getNotifications(
      { commit, state },
      payload: { skip: number; limit: number; folder: NotificationFolderEnum }
    ) {
      commit("setLoader", true);
      NotificationService.getNotifications(payload)
        .then((res) => {
          commit("setNotifications", res.data.data);
          const { items, attachments } = state.notifications;
          attachments?.forEach(
            (attachmentItem: NotificationAttachmentInterface) => {
              FileService.getFileCachedApi(attachmentItem.fileId).then(
                (imageRes) => {
                  const commentIndex = items?.findIndex(
                    (commentItem: NotificationItemInterface) =>
                      commentItem.targetId === attachmentItem.targetId
                  );
                  if (commentIndex !== -1 && items?.[commentIndex]) {
                    const updatedNotification = {
                      ...items?.[commentIndex],
                      attachments: imageRes.data.data,
                    };
                    commit("updateNotificationItem", {
                      index: commentIndex,
                      notification: updatedNotification,
                    });
                  }
                }
              );
            }
          );
        })
        .finally(() => {
          commit("setLoader", false);
        });
    },
    cleanNotifications({ commit }) {
      commit("cleanNotifications");
    },
  },
};

export default store;
