import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withModifiers as _withModifiers, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-26813479"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "part-feedback-message" }
const _hoisted_2 = { class: "part-feedback-message-header" }
const _hoisted_3 = { class: "part-feedback-message-header-name" }
const _hoisted_4 = { class: "part-feedback-message-header-team" }
const _hoisted_5 = { class: "part-feedback-message-body" }
const _hoisted_6 = { class: "part-feedback-message-created-ago" }
const _hoisted_7 = { class: "tooltip--text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["Loader"], { loading: $setup.loading }, null, 8, ["loading"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode($setup["UserAvatar"], {
        class: "part-feedback-message-header-avatar",
        id: $props.message.senderId.avatar?.id || $props.message.senderId.avatar,
        size: 24,
        name: $setup.authorName
      }, null, 8, ["id", "name"]),
      _createElementVNode("div", _hoisted_3, _toDisplayString($setup.authorName), 1),
      _createElementVNode("div", _hoisted_4, _toDisplayString($setup.authorTeam), 1),
      ($setup.isOwnMessage && $setup.notServiceMessage && $props.showActions && $setup.isTechnicalFeedbackOperationAllowedRole)
        ? (_openBlock(), _createBlock($setup["NDropdown"], {
            key: 0,
            options: $setup.messageMenuOptions,
            placement: "bottom-end",
            trigger: "click",
            onSelect: $setup.onMessageMenuOptionSelect
          }, {
            default: _withCtx(() => [
              _createVNode($setup["NButton"], {
                class: "message-menu",
                round: "",
                text: "",
                ghost: ""
              }, {
                default: _withCtx(() => [
                  _createVNode($setup["ThreeDotMenu"], { size: 18 })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["options"]))
        : _createCommentVNode("", true)
    ]),
    (!$setup.isEditMessageEnabled)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.getSplittedFeedback, (subject) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(subject), 1))
          }), 256)),
          ($props.message.attachments?.length)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(["part-details-part-feedback-gallery", {'full-width-images': $props.message.attachments?.length === 1}])
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.message.attachments, (imageItem, index) => {
                  return (_openBlock(), _createBlock($setup["GalleryImage"], {
                    key: index,
                    imageItem: imageItem.originalUploadId,
                    isActive: imageItem.originalUploadId?.link  === $props.activeImageLink && $props.activeImageLink,
                    onClick: _withModifiers(($event: any) => ($setup.onImageClick(index)), ["prevent"])
                  }, null, 8, ["imageItem", "isActive", "onClick"]))
                }), 128))
              ], 2))
            : _createCommentVNode("", true)
        ], 64))
      : (_openBlock(), _createBlock($setup["PartDetailsFeedbackMessageInput"], {
          key: 1,
          class: "part-feedback-message-body editing",
          message: $props.message.body,
          id: $props.message.id,
          editing: true,
          maxLength: $setup.partFeedbackMessageMaxLength,
          placeholder: 'Enter a feedback message',
          quoteId: $props.quoteId,
          inputFiles: $props.inputFiles,
          onMessageEdited: $setup.onMessageEdited,
          onDeleteMessageFile: _cache[0] || (_cache[0] = ($event: any) => ($setup.emit('deleteMessageFile', $event)))
        }, null, 8, ["message", "id", "maxLength", "quoteId", "inputFiles"])),
    _createVNode($setup["NTooltip"], {
      class: "tooltip--wrapper",
      trigger: "hover",
      placement: "top-start",
      "show-arrow": false
    }, {
      trigger: _withCtx(() => [
        _createElementVNode("div", _hoisted_6, _toDisplayString($setup.updatedAgo), 1)
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_7, _toDisplayString($setup.dayjs($props.message.updatedAt).format("MMM D, YYYY. HH:mm")), 1)
      ]),
      _: 1
    })
  ]))
}