import { createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["NInput"], {
    class: "search-input",
    value: $setup.searchInputValue,
    "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => (($setup.searchInputValue) = $event)),
    placeholder: $props.placeholder,
    onChange: _cache[2] || (_cache[2] = ($event: any) => ($setup.onSearch()))
  }, {
    prefix: _withCtx(() => [
      _createVNode($setup["MagnifyingGlass"], {
        width: `${$props.searchIconWidth}px`,
        height: `${$props.searchIconHeight}px`,
        onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.onSearch()))
      }, null, 8, ["width", "height"])
    ]),
    _: 1
  }, 8, ["value", "placeholder"]))
}