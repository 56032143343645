import { toDisplayString as _toDisplayString, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-44152279"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "show-more-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["NButton"], {
      class: _normalizeClass(["text-button show-more-button", {rotate: $setup.showMoreItems}]),
      iconPlacement: "right",
      onClick: $setup.toggleShowMoreItems
    }, {
      icon: _withCtx(() => [
        _createVNode($setup["NIcon"], {
          component: $setup.ArrowDown,
          size: "8"
        }, null, 8, ["component"])
      ]),
      default: _withCtx(() => [
        _createTextVNode("Show " + _toDisplayString($setup.showMoreItems ? 'less' : 'more'), 1)
      ]),
      _: 1
    }, 8, ["class"])
  ]))
}