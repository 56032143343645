import { h } from "vue";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { usePluralize } from "@/composables/usePluralize";
import { orderStatusToPercent } from "@/composables/orders/orderStatusToPercent";
import timeElapsedFormatMethods from "@/composables/timeElapsedFormatMethods";
import quoteOptionsMethods from "@/composables/quote/quoteOptionsMethods";
import millisecondsToDays from "@/constants/millisecondsToDays";
import { defaultDateFormatTemplate } from "@/constants/defaultDateFormatTemplate";
import { ProductionStageStatuses } from "@/enums/ProductionStageStatuses";
import OrderInterface from "@/types/OrderInterface";
import CustomerOrderInterface from "@/types/CustomerOrderInterface";
import { DeliveryStatuses } from "@/enums/DeliveryStatuses";
import deliveryStatusesFilter from "@/constants/filters/deliveryStatusesFilter";
import { DeliveryStatusesMfr } from "@/enums/DeliveryStatusesMfr";

dayjs.extend(customParseFormat);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const { getElapsedFromDate } = timeElapsedFormatMethods();
const { formatPrice } = quoteOptionsMethods();

export default () => {
  const formatDateToString = (date: Date) => {
    return dayjs(date).format(defaultDateFormatTemplate);
  };

  const getDeliveryDateDaysLeft = (
    deliveryDateString: string | Date,
    dueDateString: string | Date
  ): number => {
    const deliveryDate = new Date(deliveryDateString);
    deliveryDate.setHours(0, 0, 0, 0);
    const dueDate = new Date(dueDateString);
    dueDate.setHours(0, 0, 0, 0);
    return ~~(
      (dueDate.getTime() - deliveryDate.getTime()) /
      millisecondsToDays
    );
  };

  const getDateCustomer = (
    dateString: string | Date,
    targetDate: string | Date
  ) => {
    const daysLeft = getDeliveryDateDaysLeft(dateString, targetDate);
    if (!targetDate) {
      return { spanClass: "", text: "" };
    }
    if (!dateString) {
      return { spanClass: "", text: "Target" };
    }
    const moduleDaysLeft = Math.abs(daysLeft);
    if (daysLeft < 0) {
      return {
        spanClass: "days-late",
        text: `${moduleDaysLeft} ${(usePluralize(daysLeft), "Day")} Late`,
      };
    } else {
      return {
        spanClass: "on-time",
        text:
          daysLeft > 0
            ? `${moduleDaysLeft} ${(usePluralize(daysLeft), "Day")} Early`
            : "On time",
      };
    }
  };

  const getDateCustomerCellNode = (
    dateString: string | Date,
    targetDate: string | Date
  ) => {
    const { spanClass, text } = getDateCustomer(dateString, targetDate);
    return h("span", { class: spanClass }, text);
  };

  const getShippingDateCustomer = (
    deliveryStatus: DeliveryStatuses,
    deliveryDateString: string,
    dueDateString: string
  ) => {
    if (!deliveryStatus) {
      return getDateCustomer(deliveryDateString, dueDateString);
    }
    let text = deliveryStatusesFilter[deliveryStatus]?.label || "";
    if (
      deliveryDateString &&
      dueDateString &&
      [DeliveryStatuses.LATE, DeliveryStatuses.EARLY].includes(deliveryStatus)
    ) {
      const daysLeft = Math.abs(
        getDeliveryDateDaysLeft(deliveryDateString, dueDateString)
      );
      text = `${daysLeft} ${usePluralize(daysLeft, "Day")} ${text}`;
    }
    const resClass = [
      DeliveryStatuses.RESCHEDULING,
      DeliveryStatuses.TARGET,
    ].includes(deliveryStatus)
      ? ""
      : [DeliveryStatuses.EARLY, DeliveryStatuses.ON_TIME].includes(
          deliveryStatus
        )
      ? "on-time"
      : "days-late";
    return { spanClass: resClass, text };
  };

  const getShippingDateCustomerCellNode = (
    deliveryStatus: DeliveryStatuses,
    deliveryDateString: string,
    dueDateString: string
  ) => {
    const { spanClass, text } = getShippingDateCustomer(
      deliveryStatus,
      deliveryDateString,
      dueDateString
    );
    return h("span", { class: spanClass }, text);
  };

  const getProductionStatusClass = (productionStatus: string): string => {
    if (productionStatus === ProductionStageStatuses.IN_PROCESS) {
      return "in-process";
    }
    if (productionStatus === ProductionStageStatuses.PAUSE) {
      return "pause";
    }
    if (productionStatus === ProductionStageStatuses.DONE) {
      return "done";
    }
    if (productionStatus === ProductionStageStatuses.QUEUE) {
      return "queue";
    }
    if (productionStatus === ProductionStageStatuses.ERROR) {
      return "error";
    }
    return "empty";
  };

  const getManualStatusCellNode = (row: OrderInterface) => {
    const percent = orderStatusToPercent(
      row.customerStatus,
      row.mfrOrderLineId?.productionStage
    );
    return [
      row.customerStatusLastUpdate
        ? h("div", { class: "last-updated-hint" }, [
            row.customerStatusLastUpdateBy?.firstName
              ? h(
                  "div",
                  { class: "last-updated-hint-name" },
                  `${row.customerStatusLastUpdateBy.firstName} ${row.customerStatusLastUpdateBy.lastName}`
                )
              : undefined,
            h(
              "div",
              { class: "last-updated-hint-elapsed" },
              getElapsedFromDate(row.customerStatusLastUpdate)
            ),
          ])
        : undefined,
      `${percent}%`,
      h(
        "div",
        {
          class: "order-status--progress-bar",
        },
        [
          h("div", {
            class: "order-status--progress-bar order-status--progress-bar-fill",
            style: `width: ${percent}%;`,
          }),
        ]
      ),
    ];
  };

  const getShippingCellNode = (row: OrderInterface, customerView = false) => {
    const dateForCell = row.shippingDate || row.targetShippingDate;
    return [
      row.shippingDate
        ? row.shippingDateLastUpdate
          ? h("div", { class: "last-updated-hint" }, [
              h(
                "div",
                { class: "last-updated-hint-name" },
                row.shippingDateLastUpdateBy
                  ? `${row.shippingDateLastUpdateBy?.firstName} ${row.shippingDateLastUpdateBy?.lastName}`
                  : ""
              ),
              h(
                "div",
                { class: "last-updated-hint-elapsed" },
                getElapsedFromDate(row.shippingDateLastUpdate)
              ),
            ])
          : undefined
        : h("div", { class: "last-updated-hint" }, [
            h(
              "p",
              { class: "last-updated-hint-wrap" },
              "Waiting\nconfirmation"
            ),
          ]),
      dateForCell
        ? [
            h("p", formatDateToString(new Date(dateForCell))),
            h(
              "p",
              getShippingDateCustomerCellNode(
                row.deliveryStatus as DeliveryStatuses,
                row.shippingDate,
                row.targetShippingDate
              )
            ),
          ]
        : undefined,
    ];
  };

  const getShippingMethodCell = (row: OrderInterface) => {
    return h("div", {}, [
      h("p", {}, row.shippingOption?.incoterms),
      row.shippingOption?.stdShippingTime
        ? h(
            "p",
            {},
            `${row.shippingOption.stdShippingTime} ${usePluralize(
              row.shippingOption.stdShippingTime
            )}`
          )
        : undefined,
    ]);
  };

  const getDeliveryDateCell = (row: OrderInterface, customerView = false) => {
    const dateForCell = row.deliveryDate || row.dueDate;
    return h("div", { class: "delivery-date delivery-date-customer" }, [
      row.dueDate
        ? h("div", { class: "last-updated-hint" }, [
            h(
              "div",
              { class: "last-updated-hint-name last-updated-hint-wrap" },
              [
                row.dueDate
                  ? `Due date ${formatDateToString(new Date(row.dueDate))}\n`
                  : "",
                row.primaryDueDate
                  ? `Primary ${formatDateToString(
                      new Date(row.primaryDueDate)
                    )}`
                  : "",
              ]
            ),
          ])
        : undefined,
      dateForCell
        ? [
            h("p", formatDateToString(new Date(dateForCell))),
            row.shippingDate === null && row.deliveryDate === row.dueDate
              ? "Target"
              : getShippingDateCustomerCellNode(
                  row.deliveryStatus as DeliveryStatuses,
                  row.deliveryDate,
                  row.dueDate
                ),
          ]
        : undefined,
    ]);
  };

  const getMfrDeliveryDateCellCustomerView = (row: OrderInterface) => {
    if (!row?.mfrOrderLineId) {
      return h("div", []);
    }
    const dateForCell =
      row.mfrOrderLineId.deliveryDate || row.mfrOrderLineId.dueDate;
    return h("div", { class: "delivery-date delivery-date-customer" }, [
      row.mfrOrderLineId.dueDate
        ? h("div", { class: "last-updated-hint" }, [
            h(
              "div",
              { class: "last-updated-hint-name last-updated-hint-wrap" },
              [
                row.mfrOrderLineId.dueDate
                  ? `Due date ${formatDateToString(
                      new Date(row.mfrOrderLineId.dueDate)
                    )}\n`
                  : "",
              ]
            ),
          ])
        : undefined,
      dateForCell
        ? [
            h("p", formatDateToString(new Date(dateForCell))),
            row.shippingDate === null &&
            row.mfrOrderLineId.deliveryDate === row.mfrOrderLineId.dueDate
              ? "Target"
              : getShippingDateCustomerCellNode(
                  row.deliveryStatus as DeliveryStatuses,
                  row.mfrOrderLineId.deliveryDate,
                  row.mfrOrderLineId.dueDate
                ),
          ]
        : undefined,
    ]);
  };

  const getDeliveryDateCustomerCell = (row: CustomerOrderInterface) => {
    const dateForCell = row.deliveryDate || row.dueDate;

    const hintArray = [];
    if (row.deliveryDateLastUpdate) {
      hintArray.push(
        h("div", { class: "last-updated-hint-name last-updated-hint-wrap" }, [
          getElapsedFromDate(row.deliveryDateLastUpdate, true),
        ])
      );
    }
    if (row.dueDate) {
      hintArray.push(
        h("div", { class: "last-updated-hint-name last-updated-hint-wrap" }, [
          `Due date ${formatDateToString(new Date(row.dueDate))}`,
        ])
      );
    }
    if (row.primaryDueDate) {
      hintArray.push(
        h("div", { class: "last-updated-hint-name last-updated-hint-wrap" }, [
          `Primary DD ${formatDateToString(new Date(row.primaryDueDate))}`,
        ])
      );
    }

    return h("div", { class: "delivery-date delivery-date-customer" }, [
      hintArray.length
        ? h(
            "div",
            {
              class: `last-updated-hint last-updated-hint ${
                hintArray.length >= 3 ? "last-updated-hint-tall" : ""
              }`,
            },
            hintArray
          )
        : undefined,
      dateForCell
        ? [
            h(
              "p",
              {
                class:
                  "tooltip--trigger tooltip--trigger-light tooltip--trigger-absolute-icon",
              },
              formatDateToString(new Date(dateForCell))
            ),
            getShippingDateCustomerCellNode(
              row.deliveryStatus as DeliveryStatuses,
              row.deliveryDate,
              row.dueDate
            ),
          ]
        : undefined,
    ]);
  };

  const getBalance = (balance: number, unitPrice: number) => {
    return formatPrice(+(balance * unitPrice), "USD", true);
  };

  const getShippingDateText = (row: OrderInterface) => {
    const date = row.shippingDate || row.targetShippingDate;
    return date ? formatDateToString(new Date(date)) : "---";
  };

  const getDeliveryDateMfrRemarkCellNode = (
    deliveryStatus: DeliveryStatusesMfr,
    deliveryDateString: string,
    dueDateString: string,
    returnObject = false
  ) => {
    let text = deliveryStatusesFilter[deliveryStatus]?.label || "";
    if (
      deliveryDateString &&
      dueDateString &&
      [DeliveryStatusesMfr.LATE, DeliveryStatusesMfr.EARLY].includes(
        deliveryStatus
      )
    ) {
      const daysLeft = Math.abs(
        getDeliveryDateDaysLeft(deliveryDateString, dueDateString)
      );
      text = `${daysLeft} ${usePluralize(daysLeft, "Day")} ${text}`;
    }
    const colorClass = [
      DeliveryStatusesMfr.LATE,
      DeliveryStatusesMfr.UPDATE_NEEDED,
    ].includes(deliveryStatus)
      ? "days-late"
      : "on-time";
    return returnObject
      ? { text, colorClass }
      : h("span", { class: colorClass }, text);
  };

  return {
    formatDateToString,
    getDeliveryDateMfrRemarkCellNode,
    getDateCustomerCellNode,
    getDeliveryDateCustomerCell,
    getMfrDeliveryDateCellCustomerView,
    getShippingDateCustomer,
    getShippingDateCustomerCellNode,
    getDeliveryDateDaysLeft,
    getProductionStatusClass,
    getManualStatusCellNode,
    getShippingCellNode,
    getShippingMethodCell,
    getDeliveryDateCell,
    getBalance,
    getShippingDateText,
  };
};
