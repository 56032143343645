import { ref } from "vue";
import dayjs from "dayjs";
import { defaultCountOfWorkingDays } from "@/constants/defaultCountOfWorkingDays";
import { QualityControlSpeeds } from "@/enums/QualityControlSpeeds";
import { Location } from "@/enums/Location";
import { usePluralize } from "../usePluralize";
import CalendarService from "@/services/CalendarService";
import TotalShippingInterface from "@/types/quote-details/TotalShippingInterface";
import ShippingInterface from "@/types/quote-details/ShippingInterface";

export default () => {
  const getDeliveryDate = async (
    shippingBusinessDays: number,
    deliveryBusinessDays: number,
    countOfWorkingDays = defaultCountOfWorkingDays,
    orderedAt?: string,
    countryId?: string
  ) => {
    if (isNaN(deliveryBusinessDays) || isNaN(shippingBusinessDays)) {
      return "---";
    }
    if (shippingBusinessDays === 0) {
      return dayjs(new Date()).format("MMM DD, YYYY");
    }
    if (countryId) {
      return CalendarService.getDeliveryDateV2({
        date: dayjs(orderedAt ? new Date(orderedAt) : new Date()).format(
          "YYYY-MM-DD"
        ),
        shippingBusinessDays,
        deliveryBusinessDays,
        countryId,
      }).then((res) => {
        if (res.data.data.deliveryDate === 0) {
          return getDateByCountOfWorkingDays(
            shippingBusinessDays + deliveryBusinessDays,
            countOfWorkingDays,
            orderedAt
          );
        }
        shippingDate.value = res.data.data.shippingDate;
        return dayjs(res.data.data.deliveryDate).format("MMM DD, YYYY");
      });
    }
    return getDateByCountOfWorkingDays(
      shippingBusinessDays + deliveryBusinessDays,
      countOfWorkingDays,
      orderedAt
    );
  };

  const getDateByCountOfWorkingDays = (
    days: number,
    countOfWorkingDays = defaultCountOfWorkingDays,
    orderedAt?: string
  ) => {
    const newDate = new Date(
      (orderedAt ? new Date(orderedAt) : new Date()).getTime() +
        Math.ceil((days / countOfWorkingDays) * 7) * 24 * 60 * 60 * 1000
    );
    const result = newDate.toDateString();
    return `${result.substring(
      result.indexOf(" ") + 1,
      result.lastIndexOf(" ")
    )}, ${newDate.getFullYear()}`;
  };

  const shippingDate = ref("");
  const getShippingInfo = async (
    days: number,
    countOfWorkingDays?: number,
    orderedAt?: string,
    countryId?: string
  ) => {
    return getDeliveryDate(
      days,
      0,
      countOfWorkingDays,
      orderedAt,
      countryId
    ).then((res) => {
      return `Shipped by ${res} (${days} business ${usePluralize(days)})`;
    });
  };

  const getDeliveryInfo = async (
    option: TotalShippingInterface | ShippingInterface,
    shippingDays: number,
    partsProductionDays: number,
    countOfWorkingDays?: number,
    orderedAt?: string,
    countryId?: string
  ) => {
    if (
      shippingDays === undefined ||
      partsProductionDays === undefined ||
      option.incoterms === undefined
    ) {
      return "";
    }
    return getDeliveryDate(
      partsProductionDays,
      shippingDays,
      countOfWorkingDays,
      orderedAt,
      countryId
    ).then((res) => {
      return `Delivered by ${res} (${
        option.incoterms
      }, ${shippingDays} business ${usePluralize(shippingDays)})`;
    });
  };

  const getPartsProductionDeliveryInfo = async (
    days: number,
    countOfWorkingDays?: number,
    orderedAt?: string,
    countryId?: string
  ) => {
    return getDeliveryDate(
      days,
      0,
      countOfWorkingDays,
      orderedAt,
      countryId
    ).then((res) => {
      return `Delivered by ${res} (${days} business ${usePluralize(days)})`;
    });
  };

  const getCountryIdByRegion = (mfrRegion: string, id: string) => {
    return mfrRegion === Location.CHINA ? id : undefined;
  };

  const camelize = (str: string) => {
    if (str === QualityControlSpeeds.FaiReport) {
      return "FAIReport";
    }
    if (str === QualityControlSpeeds.CtqReport) {
      return "CTQReport";
    }
    if (str === "Ship with your courier") {
      return "courier";
    }
    return str
      .split(" ")
      .map((word, index) => {
        if (index === 0) {
          return word.toLowerCase();
        }
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      })
      .join("");
  };

  const getClassByLevel = (level: string) => {
    return level.toLowerCase().replace(/\s/g, "-");
  };

  const formatPrice = (
    price: number | string,
    currency = "USD",
    withSpace = false,
    digits = 1
  ) => {
    if (price === null || price === undefined || isNaN(+price)) {
      return "---";
    }
    const priceString = (+price).toLocaleString("en-US", {
      style: "currency",
      currency: currency ? currency : "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: digits,
    });
    return withSpace ? priceString.replace("$", "$ ") : priceString;
  };

  const roundNumber = (number: number, digits = 0) => {
    const factor = Math.pow(10, digits);
    return Math.round(number * factor) / factor;
  };

  const formatPercentValue = (percent: number) => {
    return `${(percent * 100).toFixed()}%`;
  };

  const convertArrayToString = (value: string[]) => {
    return value?.join(", ");
  };
  return {
    getDeliveryDate,
    getShippingInfo,
    getDeliveryInfo,
    getPartsProductionDeliveryInfo,
    getCountryIdByRegion,
    camelize,
    getClassByLevel,
    formatPrice,
    formatPercentValue,
    convertArrayToString,
    roundNumber,
    shippingDate,
  };
};
