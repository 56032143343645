import { ref } from "vue";
import router from "@/router";
import QuoteService from "@/services/QuoteService";
import { QuoteRevision } from "@/types/api/QuoteInterface";

export default () => {
  const initRevisionListLoading = ref(false);
  const quoteRevisionList = ref<QuoteRevision[]>([] as QuoteRevision[]);
  const fetchQuoteV2RevisionList = () => {
    initRevisionListLoading.value = true;
    return QuoteService.getQuoteV2RevisionList(
      `${router.currentRoute.value.params.id}`
    )
      .then((res) => {
        quoteRevisionList.value = res.data.data;
      })
      .finally(() => {
        initRevisionListLoading.value = false;
      });
  };
  return {
    initRevisionListLoading,
    quoteRevisionList,
    fetchQuoteV2RevisionList,
  };
};
